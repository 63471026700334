<script>
import { AxiosAPI } from '@/axios';
import moment from 'moment';
import { useMainStore } from '../../stores/index';
/* import { LineChart } from 'vue-chart-3'; */
import Kaban from '../documents/Kaban/kaban';
import { Chart, registerables } from 'chart.js';
/* import SignerSituation from './AtomComponents/SignerSituation.vue';
import SignerPen from './AtomComponents/SignerPen.vue';
import RequiredSigner from './AtomComponents/RequiredSigner.vue'; */
Chart.register(...registerables);

export default {
  name: 'DashboardDefault',
  components: { Kaban/* , SignerSituation, SignerPen, RequiredSigner  */ },
  setup () {
    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: 'bottom',
          title: {
            display: true
          },
          labels: {
            usePointStyle: true
          }
        }
      }
    };
    const StoreAll = useMainStore();
    StoreAll.Token();
    return { Store: StoreAll, options };
  },
  data () {
    return {
      loading: true,
      page: 1,
      optionspag: [],
      componenteHeight: '93vh',
      DocumentsSigner: [],
      NotViewed: 0,
      DocSend: 0,
      DocRecep: 0,
      DocSign: 0,
      DocLessSign: 0,
      date_start: '',
      date_end: '',
      showPassword: false,
      testData: {
        labels: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho'],
        datasets: [
          {
            label: 'Assinados',
            backgroundColor: '#479F76',
            borderColor: '#479F76',
            pointBorderWidth: 4,
            borderWidth: 2,
            data: [10, 20, 30, 40, 50, 60, 70]
          },
          {
            label: 'Enviados',
            backgroundColor: '#F1860B',
            borderColor: '#F1860B',
            pointBorderWidth: 4,
            borderWidth: 2,
            data: [100, 0, 20, 45, 28, 10, 87]
          },
          {
            label: 'Recebidos',
            backgroundColor: '#0D6EFD',
            borderColor: '#0D6EFD',
            pointBorderWidth: 4,
            borderWidth: 2,
            data: [40, 39, 10, 40, 39, 80, 40]
          }
        ]
      },
      DocumentSignerId: 0,
      progress: 0,
      SignerSucess: false,
      NameDocSigner: [],
      wrongPasswords: false,
      password: '',
      nowDay: '',
      SignerAttachSucess: false,
      SignerWithAttachSucess: false
    };
  },
  watch: {
    date_start: function (newValue) {
      if (this.date_start !== '' && this.date_end !== '' && this.date_start < this.date_end) {
        this.FilterActivity();
      }
      if (this.date_start !== '' && this.date_end === '' && this.date_start < this.date_end) {
        this.FilterActivity();
      }
      if (this.date_start === '' && this.date_end !== '' && this.date_start < this.date_end) {
        this.FilterActivity();
      }
    },
    date_end: function (newValue) {
      if (this.date_start !== '' && this.date_end !== '' && this.date_start < this.date_end) {
        this.FilterActivity();
      }
      if (this.date_start !== '' && this.date_end === '' && this.date_start < this.date_end) {
        this.FilterActivity();
      }
      if (this.date_start === '' && this.date_end !== '' && this.date_start < this.date_end) {
        this.FilterActivity();
      }
    }
  },
  mounted () {
    this.checkComponentHeight();
    this.GetNumbers();
    this.Store.DepartamentosAll();
    this.GetDocs();
    window.addEventListener('resize', this.checkComponentHeight);
    this.getDay('');
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.checkComponentHeight);
  },
  methods: {
    checkComponentHeight () {
      const componente = document.getElementById('Dashboard');
      const alturaViewport = window.innerHeight;
      if (componente.clientHeight < 0.93 * alturaViewport) {
        this.componenteHeight = '92.5vh';
      } else {
        this.componenteHeight = componente.clientHeight < 0.93 * alturaViewport + 'vh'; // Adaptar ao tamanho adicional
      }
    },

    GetDocs () {
      this.loading = true;
      AxiosAPI
        .get('api/documents/signature-requirement?page=' + this.page + '&status=pending', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.Store.accessToken
          }
        }).then((response) => {
          this.DocumentsSigner = response.data.documents.data;
          this.optionspag = response.data.documents;
          this.loading = false;
        });
    },

    onPageChange (event) {
      this.page = event.page + 1;
      this.GetDocs();
    },

    formatDate (value) {
      moment.locale('pt-br');
      return moment(value).format('DD/MMMM/YYYY');
    },

    GetNumbers () {
      this.loading = true;
      const today = new Date();
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(today.getDate() - 30);

      const options = { year: 'numeric', month: '2-digit', day: '2-digit' };

      const todayFormatted = today.toLocaleDateString('en-US', options);
      const thirtyDaysAgoFormatted = thirtyDaysAgo.toLocaleDateString('en-US', options);

      const yearStart = thirtyDaysAgo.getFullYear();
      const monthStart = String(thirtyDaysAgo.getMonth() + 1).padStart(2, '0');
      const dayStart = String(thirtyDaysAgo.getDate()).padStart(2, '0');

      const yearend = today.getFullYear();
      const monthend = String(today.getMonth() + 1).padStart(2, '0');
      const dayend = String(today.getDate()).padStart(2, '0');

      this.date_start = `${yearStart}-${monthStart}-${dayStart}`;
      this.date_end = `${yearend}-${monthend}-${dayend}`;
      AxiosAPI
        .get('/api/documents/activity',
          {
            params: {
              date_start: thirtyDaysAgoFormatted,
              date_end: todayFormatted
            },
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + this.Store.accessToken
            }
          }).then((response) => {
          this.NotViewed = response.data.documents.not_viewed;
          this.DocSend = response.data.documents.sents;
          this.DocRecep = response.data.documents.receiveds;
          this.DocSign = response.data.documents.signeds;
          this.DocLessSign = response.data.documents.to_sign;
          this.loading = false;
        });
    },

    FilterActivity () {
      const receveds = [];
      const signers = [];
      const sents = [];
      const months = [];
      const monthsNumb = [];
      const currentDate = new Date(this.date_start);
      const endDate = new Date(this.date_end);
      while (currentDate <= endDate) {
        const month = currentDate.toLocaleString('default', { month: 'long', year: 'numeric' });
        const monthNumb = currentDate.toLocaleString('default', { month: 'numeric', year: 'numeric' });
        months.push(month);
        monthsNumb.push(monthNumb);
        currentDate.setMonth(currentDate.getMonth() + 1);
      }
      AxiosAPI
        .get('/api/documents/activity/by-date',
          {
            params: {
              date_start: this.date_start,
              date_end: this.date_end
            },
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + this.Store.accessToken
            }
          }).then((response) => {
          receveds.push(...Object.values(response.data.documents.receiveds));
          signers.push(...Object.values(response.data.documents.signeds));
          sents.push(...Object.values(response.data.documents.sents));
          this.testData = {
            labels: months,
            datasets: [
              {
                label: 'Assinados',
                color: '#479F76',
                backgroundColor: '#479F76',
                data: signers
              },
              {
                label: 'Enviados',
                backgroundColor: '#F1860B',
                data: sents
              },
              {
                label: 'Recebidos',
                backgroundColor: '#0D6EFD',
                data: receveds
              }
            ]
          };
        });
    },

    async Signer () {
      this.wrongPasswords = false;
      document.getElementById('closeModal').click();
      const interval = setInterval(() => {
        if (this.progress < 90) {
          this.progress += 10;
        }
      }, 1000);
      this.$refs.hiddenButtons.click();
      await AxiosAPI
        .post(`api/documents/${this.DocumentSignerId}/sign`, { user_id: this.Store.user.id, password: this.password }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.Store.accessToken
          }
        }).then((resp) => {
          this.progress = 100;
          clearInterval(interval);
          setTimeout(() => {
            this.password = '';
            this.NameDocSigner = resp.data.document;
            this.SignerSucess = true;
            this.GetDocs();
            this.wrongPasswords = false;
            this.$refs.hiddenButtonCloses.click();
            this.progress = 0;
          }, 2000);
        }).catch((error) => {
          console.log(error);
          this.progress = 0;
          this.$refs.hiddenButtonCloses.click();
          this.wrongPasswords = true;
          this.$refs.wrongPassword.click();
          clearInterval(interval);
        });
    },
    async SignerAttach () {
      document.getElementById('SignerAttachcloseModal').click();
      const interval = setInterval(() => {
        if (this.progress < 90) {
          this.progress += 10;
        }
      }, 1000);
      this.$refs.hiddenButtons.click();
      await AxiosAPI
        .post(`api/documents/${this.DocumentSignerId}/sign-attachment`, { user_id: this.Store.user.id, password: this.password }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.Store.accessToken
          }
        }).then((resp) => {
          this.progress = 100;
          console.log(resp);
          clearInterval(interval);
          setTimeout(() => {
            this.password = '';
            this.SignerAttachSucess = true;
            this.GetDocs();
            this.$refs.hiddenButtonCloses.click();
            this.progress = 0;
          }, 2000);
        }).catch((error) => {
          console.log(error);
          this.progress = 0;
          this.$refs.hiddenButtonCloses.click();
          this.wrongPasswords = true;
          this.$refs.AttachwrongPassword.click();
          clearInterval(interval);
        });
    },
    async SignerWithAttach () {
      document.getElementById('SignerWithAttachcloseModal').click();
      const interval = setInterval(() => {
        if (this.progress < 90) {
          this.progress += 10;
        }
      }, 1000);
      this.$refs.hiddenButtons.click();
      await AxiosAPI
        .post(`api/documents/${this.DocumentSignerId}/sign`, { user_id: this.Store.user.id, password: this.password }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.Store.accessToken
          }
        }).then((resp) => {
          console.log(resp);
          this.NameDocSigner = resp.data.document;
        }).catch((error) => {
          console.log(error);
          this.progress = 0;
          this.$refs.hiddenButtonCloses.click();
          this.wrongPasswords = true;
          this.$refs.WithAttachwrongPassword.click();
          clearInterval(interval);
        });

      await AxiosAPI
        .post(`api/documents/${this.DocumentSignerId}/sign-attachment`, { user_id: this.Store.user.id, password: this.password }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.Store.accessToken
          }
        }).then((resp) => {
          this.progress = 100;
          console.log(resp);
          clearInterval(interval);
          setTimeout(() => {
            this.password = '';
            this.SignerWithAttachSucess = true;
            this.GetDocs();
            this.$refs.hiddenButtonCloses.click();
            this.progress = 0;
          }, 2000);
        });
    },

    async ViewDoc (value) {
      await AxiosAPI
        .post('/api/documents/' + value.id + '/view', {}, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.Store.accessToken
          }
        }).then(() => {
          this.GetDocs();
        });
    },

    SignerStart () {
      this.password = '';
      this.wrongPasswords = false;
    },

    getDay (value) {
      const ajustarData = (meses) => {
        const hoje = new Date();
        const novaData = new Date(hoje.setMonth(hoje.getMonth() + meses));
        moment.locale('pt-br');
        const mes = moment(novaData).format('MMMM').toUpperCase();
        const ano = novaData.getFullYear();
        return `${mes} DE ${ano}`;
      };

      // Lógica para decidir o valor de ajuste
      if (value === '') {
        this.nowDay = ajustarData(0); // Data atual
      } else if (value === 'more') {
        this.nowDay = ajustarData(1); // Próximo mês
      } else if (value === 'less') {
        this.nowDay = ajustarData(-1); // Mês anterior
      }
    },

    DocumentSignerIds (value) {
      this.SignerStart();
      this.DocumentSignerId = value;
    }
  }
};
</script>
<style>
.boxSDashboard {
  width: 92px;
  height: 61px;
  border-radius: 8px;
  background-color: #E7F1FF;
  padding: 16px;
}
.boxSDashboard-pendente {
  width: 92px;
  height: 61px;
  border-radius: 8px;
  background-color: #F1860B;
  padding: 16px;
}
.kaban-docs-main{
  width: 100%;
  overflow-x: auto;
}

</style>
<template>
  <div class='py-4 container-fluid d-grid' id="Dashboard">
      <div v-if="SignerSucess" class="d-flex justify-content-between align-items-center" style="background-color:#BADBCC;height: 50px;">
        <div>
          <i class="fa fa-check-circle ml-2 mr-2" aria-hidden="true" style="color:#0F5132; font-size: 15px;"></i>
          <span class="font-docs" style="color: #0F5132"> <b> {{NameDocSigner.protocol}} despacho - {{ NameDocSigner.dispatch }} assinado!  </b> </span>
        </div>
        <div class="d-flex justify-content-center align-items-center" style="width: 56px; height: 56px">
          <font-awesome-icon :icon="['fas', 'x']" style="color:rgb(125 120 127); cursor: pointer" @click="SignerSucess = false" />
        </div>
      </div>
      <div v-if="SignerWithAttachSucess" class="d-flex justify-content-between align-items-center" style="background-color:#BADBCC;height: 50px;">
          <div>
            <i class="fa fa-check-circle ml-2 mr-2" aria-hidden="true" style="color:#0F5132; font-size: 15px;"></i>
            <span class="font-docs" style="color: #0F5132"> <b> {{NameDocSigner.protocol}} despacho - {{ NameDocSigner.dispatch }} e anexo(os) assinado(os)! </b> </span>
          </div>
          <div class="d-flex justify-content-center align-items-center" style="width: 56px; height: 56px">
              <font-awesome-icon :icon="['fas', 'x']" style="color:rgb(125 120 127); cursor: pointer" @click="SignerWithAttachSucess = false" />
          </div>
        </div>
        <div v-if="SignerAttachSucess" class="d-flex justify-content-between align-items-center" style="background-color:#BADBCC;height: 50px;">
          <div>
            <i class="fa fa-check-circle ml-2 mr-2" aria-hidden="true" style="color:#0F5132; font-size: 15px;"></i>
            <span class="font-docs" style="color: #0F5132"> <b> Anexo(os) assinado(os)! </b> </span>
          </div>
          <div class="d-flex justify-content-center align-items-center" style="width: 56px; height: 56px">
              <font-awesome-icon :icon="['fas', 'x']" style="color:rgb(125 120 127); cursor: pointer" @click="SignerAttachSucess = false" />
          </div>
        </div>
      <button type="button" ref="hiddenButtons" class="btn btn-primary d-none" data-toggle="modal" data-target="#Loading"></button>

    <div class="kaban-docs-main">
      <Kaban />
    </div>
  </div>
</template>
