<script>
import { useMainStore } from '../../../stores/index';
import { AxiosAPI } from '@/axios';
import ModalTask from './ModalTask.vue';
import ModalCreateTask from './ModalCreateTask.vue';
import moment from 'moment';
export default {
  name: 'KabanDocs',
  components: { ModalTask, ModalCreateTask },
  setup () {
    const StoreAll = useMainStore();
    return {
      Store: StoreAll
    };
  },
  data () {
    return {
      dataAtual: new Date(),
      taskLabels: {
        start: 'À INICIAR',
        executed: 'EM EXECUÇÃO',
        bloqued: 'BLOQUEADO',
        finish: 'CONCLUÍDA'
      },
      taskList: {
        start: [],
        executed: [],
        finish: [],
        bloqued: []
      },
      days: {
        Segunda: [],
        Terça: [],
        Quarta: [],
        Quinta: [],
        Sexta: [],
        Sábado: [],
        Domingo: []
      },
      allDays: {
        Segunda: [],
        Terça: [],
        Quarta: [],
        Quinta: [],
        Sexta: []
      },
      newTask: '',
      draggedTask: null,
      draggedFrom: null,
      sourceDay: null,
      selectDayModel: [],
      TaskName: '',
      taskInputsVisibility: {},
      loading: false,
      NewOrdem: [],
      dateNow: '',
      dateSearch: ''
    };
  },

  computed: {
    startOfWeek () {
      if (this.dateSearch === '') {
        const currentDate = new Date();
        return moment(currentDate).startOf('isoWeek');
      } else {
        return moment(this.dateSearch).startOf('isoWeek');
      }
    },

    formatDateWeekMobile () {
      const weekdays = ['Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado', 'Domingo'];

      // Criar um novo objeto com os valores de segunda a sexta
      const formattedDays = weekdays.reduce((acc, day) => {
        acc[day] = this.days[day];
        return acc;
      }, {});

      return formattedDays;
    },

    formatDateWeek () {
      const weekdays = ['Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta'];

      // Criar um novo objeto com os valores de segunda a sexta
      const formattedDays = weekdays.reduce((acc, day) => {
        acc[day] = this.days[day];
        return acc;
      }, {});

      return formattedDays;
    },

    formatDateWeekend () {
      const weekdays = ['Sábado', 'Domingo'];

      // Criar um novo objeto com os valores de segunda a sexta
      const formattedDays = weekdays.reduce((acc, day) => {
        acc[day] = this.days[day];
        return acc;
      }, {});

      return formattedDays;
    }
  },
  mounted () {
    this.getSemanaDoMes(this.dataAtual);
    this.emitter.on('KabanUpdated', () => {
      this.getTask();
    });
  },
  methods: {
    getDateForDay (index) {
      return this.startOfWeek.clone().add(index, 'days').format('DD');
    },

    isToday (index) {
      const currentDate = new Date();
      const dayOfWeek = this.startOfWeek.clone().add(index, 'days').format('YYYY-MM-DD');
      const today = currentDate.toISOString().split('T')[0];

      return dayOfWeek === today;
    },

    getDate (index) {
      return this.startOfWeek.clone().add(index, 'days').format('MM/DD/YYYY');
    },

    getDatebr (index) {
      return this.startOfWeek.clone().add(index, 'days').format('YYYY/MM/DD');
    },

    formatdateToEdit (value) {
      moment.locale('en-us');
      return moment(value).format('yyyy-MM-DD');
    },

    async getTask () {
      await AxiosAPI.get('/api/tasks?reference_day=' + this.formatdateToEdit(this.dateSearch), {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.Store.accessToken
        }
      }).then((resp) => {
        this.days = {
          Segunda: resp.data.tasks.monday,
          Terça: resp.data.tasks.tuesday,
          Quarta: resp.data.tasks.wednesday,
          Quinta: resp.data.tasks.thursday,
          Sexta: resp.data.tasks.friday,
          Sábado: resp.data.tasks.saturday,
          Domingo: resp.data.tasks.sunday
        };

        this.allDays = {
          ...this.days
        };
      });
    },

    selectDay (value) {
      this.$refs.ModalTask.openTask(value);
    },

    CreateTask (value, type) {
      this.$refs.ModalCreateTask.openCreateTask(this.getDate(value), type);
    },

    async addTask (value) {
      this.loading = true;
      let dataValor = 0;
      if (value === 'Segunda') { dataValor = '1'; };
      if (value === 'Terça') { dataValor = '2'; };
      if (value === 'Quarta') { dataValor = '3'; };
      if (value === 'Quinta') { dataValor = '4'; };
      if (value === 'Sexta') { dataValor = '5'; };
      if (value === 'Sábado') { dataValor = '6'; };
      if (value === 'Domingo') { dataValor = '7'; };
      if (value === 'metas') { dataValor = '8'; };
      await AxiosAPI
        .post('/api/tasks/',
          {
            weekday: dataValor,
            title: this.TaskName,
            users: [this.Store.user.id]
          }, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + this.Store.accessToken
            }
          }).then(() => {
          this.TaskName = '';
          this.taskInputsVisibility = {};
          this.loading = false;
        });
    },

    toggleTaskInput (day) {
      this.TaskName = '';
      this.taskInputsVisibility = {
        ...this.taskInputsVisibility,
        [day]: !this.taskInputsVisibility[day]
      };
    },

    onDragStart (task, day) {
      this.draggedTask = task;
      this.sourceDay = day;
    },

    onDragOverTask (targetTask) {
      if (this.draggedTask.id === targetTask.id) return;
      const dayTasks = this.allDays[this.sourceDay];

      const draggedIndex = dayTasks.findIndex((t) => t.id === this.draggedTask.id);
      const targetIndex = dayTasks.findIndex((t) => t.id === targetTask.id);

      dayTasks.splice(draggedIndex, 1);
      dayTasks.splice(targetIndex, 0, this.draggedTask);

      this.NewOrdem = dayTasks;
      console.log('NewOrdem', this.NewOrdem);
      this.syncDays();
    },

    onDragOver (event) {
      event.preventDefault();
    },

    onDrop (targetDay, taskDrop, tasks, index) {
      console.log('onDrop', targetDay, taskDrop, tasks, this.getDatebr(index));
      const dayMap = {
        Segunda: '1',
        Terça: '2',
        Quarta: '3',
        Quinta: '4',
        Sexta: '5',
        Sábado: '6',
        Domingo: '7',
        metas: '8'
      };
      const dataValor = dayMap[targetDay] || '0';

      if (dataValor !== '0' && this.draggedTask.weekday !== dataValor) {
        if (!this.draggedTask || !this.sourceDay) return;

        this.allDays[this.sourceDay] = this.allDays[this.sourceDay].filter(
          (t) => t.id !== this.draggedTask.id
        );

        this.allDays[targetDay].push(this.draggedTask);

        this.syncAroundDays();

        AxiosAPI.put(`/api/tasks/${this.draggedTask.id}`, {
          date: this.getDatebr(index)
          /* title: this.draggedTask.title,
          author_id: this.draggedTask.author.id,
          order: 1,
          users: oldUsers,
          labels: this.draggedTask.labels */
        }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.Store.accessToken}`
          }
        }).then(() => {
          this.getTask();
        }).catch((error) => {
          console.error('Erro ao atualizar tarefa:', error);
          this.allDays[this.sourceDay].push(this.draggedTask);
        });

        this.draggedTask = null;
        this.sourceDay = null;
      }

      if (taskDrop) {
        const adicionarOrdem = (tarefas) => {
          return tarefas.map((tarefa, index) => ({
            ...tarefa,
            ordem: index
          }));
        };
        const dayTasks = adicionarOrdem(this.NewOrdem);

        const ordem = [];
        dayTasks.forEach(ordemDays => {
          ordem.push(ordemDays.id);
        });
        AxiosAPI.patch('/api/tasks/change-order', {
          weekday: dayTasks[0].weekday,
          tasks: ordem
        }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.Store.accessToken}`
          }
        }).then((resp) => {
          console.log('Ordem da tarefa atualizada com sucesso', resp);
          this.getTask();
        }).catch((error) => {
          console.error('Erro ao atualizar tarefa:', error);
          this.allDays[this.sourceDay].push(this.draggedTask);
        });
      }
    },

    updateTaskOrder (day) {
      const ordem = [];
      day.forEach(ordemDays => {
        ordem.push(ordemDays.id);
      });

      AxiosAPI.patch('api/tasks/change-order ', {
        weekday: day[0].weekday,
        tasks: ordem
      }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.Store.accessToken}`
        }
      })
        .then(() => {
          this.getTask();
          console.log('Ordem da tarefa atualizada com sucesso');
        })
        .catch((error) => {
          console.error('Erro ao atualizar ordem da tarefa:', error);
        });
    },
    syncAroundDays () {
      this.days = {
        Segunda: [...this.allDays.Segunda],
        Terça: [...this.allDays.Terça],
        Quarta: [...this.allDays.Quarta],
        Quinta: [...this.allDays.Quinta],
        Sexta: [...this.allDays.Sexta],
        Sábado: [...this.allDays.Sábado],
        Domingo: [...this.allDays.Domingo]
      };
    },

    syncDays () {
      this.days = {
        Segunda: [...this.allDays.Segunda],
        Terça: [...this.allDays.Terça],
        Quarta: [...this.allDays.Quarta],
        Quinta: [...this.allDays.Quinta],
        Sexta: [...this.allDays.Sexta],
        Sábado: [...this.allDays.Sábado],
        Domingo: [...this.allDays.Domingo]
      };
    },

    handleTaskEdit () {
      this.getTask();
    },

    formatHour (value) {
      return moment(value, 'HH:mm:ss').format('HH:mm');
    },

    getSemanaDoMes (data) {
      const date = new Date(data);

      const primeiroDiaDoMes = new Date(date.getFullYear(), date.getMonth(), 1);

      const diff = (date - primeiroDiaDoMes) / (1000 * 60 * 60 * 24);

      const semana = Math.ceil((diff + 1) / 7);

      this.dateSearch = date;
      this.dateNow = `${semana}ª semana de ${date.toLocaleString('pt-BR', { month: 'long' })}/${date.getFullYear()}`;
      this.getTask();
    },

    avancarSemana () {
      this.dataAtual.setDate(this.dataAtual.getDate() + 7);
      this.getSemanaDoMes(this.dataAtual);
    },

    voltarSemana () {
      this.dataAtual.setDate(this.dataAtual.getDate() - 7);
      this.getSemanaDoMes(this.dataAtual);
    },

    resetWeeks () {
      this.dataAtual = new Date();
      this.getSemanaDoMes(this.dataAtual);
    }
  }
};
</script>
<style scoped>
.kaban{
  display: flex;
  width: 100%;
}
.kaban-collum{
  display: block;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 8px;
  width: 100%;
  min-height: 687px;
  background-color:#E9ECEF;
}

.kaban-collum-weekend{
  display: block;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  width: 100%;
  min-height: 340px;
  background-color:#E9ECEF;
}

.kaban-task-list{
  background-color:#E9ECEF;
  margin-bottom: 16px;
  width: 100%;
}
.kaban-footer{
  position: relative;
  top: 150px;
}

.days .weekend .days-weekend .metas{
  border: 1px solid black;
  border-radius: 8px;
  padding: 10px;
  text-align: center;
}

.days{
  display: flex;
  gap: 20px;
  width: 100%;
}

.days-weekend {
  display: grid;
  gap: 20px;
  width: 20%;
}

.task {
  background-color: #FFFFFF;
  width: 100%;
  height: auto;
  border-radius: 5px;
  padding: 16px;
  justify-content: center;
  align-items: center;
}

.add-task {
  margin-top: auto;
  padding: 8px 0;
  cursor: pointer;
  background-color: #e0e0e0;
  border-radius: 8px;
  transition: background-color 0.3s;
}

.add-task:hover{
  background-color: #797979;
}

.add-task-weekend:hover{
  background-color: #797979;
}

.add-task-meta:hover{
  background-color: #797979;
}
.add-task-weekend{
  top: 0px;
  cursor: pointer;
}

.add-task-meta {
  top: 0px;
  cursor: pointer;
}
.metas .kaban-collum {
  width: 100%;
}
.metas{
  grid-column: 1 / -1;
}
.move-enter-active, .move-leave-active {
  transition: transform 0.3s;
}
.move-enter, .move-leave-to {
  transform: translateY(30px);
  opacity: 0;
}
@media screen and (max-width: 1390px) {
  .kaban{
    width: 1350px;
  }
}

@media screen and (max-width: 625px) {
  .kaban{
    width: 100%;
  }

.kaban-collum{
  display: block;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 8px;
  width: 100%;
  min-height: 200px;
  background-color:#E9ECEF;
}
.kaban-task-list{
  display: flex;
  overflow-x: auto;
}
.days{
  display: block;
  gap: 20px;
  width: 100%;
}

.task {
  background-color: #FFFFFF;
  width: 465px;
  height: auto;
  border-radius: 5px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  margin-right: 0.75rem;
}

}
@media screen and (max-width: 555px) {
.task {
  background-color: #FFFFFF;
  width: 350px;
  height: auto;
  border-radius: 5px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  margin-right: 0.75rem;
}
}

@media screen and (max-width: 500px) {
.task {
  background-color: #FFFFFF;
  width: 250px;
  height: auto;
  border-radius: 5px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  margin-right: 0.75rem;
}
}
</style>
<template>
<div>
<div v-if="!Store.mobile" class="w-100">
    <ModalTask ref="ModalTask" @taskDescription="handleTaskEdit"/>
    <ModalCreateTask ref="ModalCreateTask" @CreateTask="handleTaskEdit"/>
    <div class="d-flex align-items-center">
      <i class="fa-solid fa-chevron-left mr-2" style="font-size: 28px; cursor:pointer" @click="voltarSemana()"></i>
      <i class="fa-solid fa-chevron-right mr-3" style="font-size: 28px; cursor:pointer" @click="avancarSemana()"></i>
      <span class="font-docs" style="font-size: 28px"><b>{{dateNow}}</b></span>
      <i class="fa-solid fa-arrows-rotate ml-3" style="font-size: 28px; cursor:pointer" @click="resetWeeks()"></i>
    </div>
    <div class="kaban">
        <div class="days">

          <div v-for="(tasks, day, index) in formatDateWeek" :key="day"
            class="kaban-collum"
            @dragover.prevent="onDragOver"
            @drop="onDrop(day, false, tasks, index)"
            >
              <div class="font-docs text-start d-flex justify-content-between w-100 mb-2" style="align-items: baseline;">
                <div class="ml-2">
                  <span class="mr-1" style="color:#6C757D; font-size:14px"><b>{{ day.slice(0, 3) }}</b></span>
                  <span><b>{{ getDateForDay(index) }}</b></span>
                  <span v-if="isToday(index+1)" class="font-docs ml-1"> <b>(Hoje)</b></span>
                </div>
                <div class="mr-1">
                  <i class="fa-solid fa-plus" style="color:#6C757D; cursor:pointer" @click="CreateTask(index, 0)"></i>
                </div>
              </div>
            <div class="kaban-task-list">
              <transition-group name="move" tag="div">
                <div
                  v-for="(task, index) in tasks"
                  :key="index"
                  class="task font-docs mb-2"
                  style="cursor: pointer;"
                  @click="selectDay(task)"
                  @dragstart="onDragStart(task, day)"
                  @dragover.prevent="onDragOverTask(task)"
                  @drop="onDrop(task, true)"
                  @dragleave="onDragLeaveTask"
                  draggable="true"
                >
                <div v-if="task.situation" class="mt-2 mb-2 d-flex justify-content-between align-items-center">
                  <div class="d-flex align-items-center">
                    <i v-if="task.situation !== '3'" class="fa-regular fa-circle-check mr-2"></i>
                    <i v-if="task.situation === '3'" class="fa-solid fa-circle-check"></i>
                    <Tag v-if="task.situation === '1'" rounded style='background-color: #E9ECEF;color:#6C757D;'>
                      <div class='d-flex align-items-center gap-2'>
                        <span class='font-docs'><b>À iniciar</b></span>
                      </div>
                    </Tag>
                    <Tag v-if="task.situation === '2'" rounded style='background-color: #CFF4FC;color: #087990;'>
                      <div class='d-flex align-items-center gap-2'>
                        <span class='text-base font-docs'><b>Em andamento</b></span>
                      </div>
                    </Tag>
                    <Tag v-if="task.situation === '4'" rounded style='background-color: #F8D7DA;color: #842029;'>
                      <div class='d-flex align-items-center gap-2'>
                        <span class='text-base font-docs'><b>Bloqueada</b></span>
                      </div>
                    </Tag>
                    <Tag v-if="task.situation === '3'" rounded style='background-color: rgba(9,167, 6, 0.1);color: rgb(9 167 6);'>
                      <div class='d-flex align-items-center gap-2'>
                        <span class='text-base font-docs'><b>Concluída</b></span>
                      </div>
                    </Tag>
                  </div>
                  <div>
                    <div v-if="task.priority === '1'" class="d-flex">
                    <i class="fa-solid fa-angle-up mr-1" style="color:#0D6EFD;position: relative; top: 5px;"></i>
                    </div>

                    <div v-if="task.priority === '2'" class="d-flex">
                      <div class="d-grid mr-1">
                        <i class="fa-solid fa-angle-up" style="color:#FFC107;position: relative; top: 10px;"></i>
                        <i class="fa-solid fa-angle-up" style="color:#FFC107;"></i>
                      </div>
                    </div>

                    <div v-if="task.priority === '3'" class="d-flex">
                      <div class="d-grid mr-1">
                        <i class="fa-solid fa-angle-up" style="color:#F76659; position: relative; top: 10px;"></i>
                        <i class="fa-solid fa-angle-up" style="color:#F76659; position: relative; top: 0px;"></i>
                        <i class="fa-solid fa-angle-up" style="color:#F76659; position: relative; top: -10px;"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mb-3 mt-2">
                  <span v-if="task.situation !== '3'">{{ task.title }}</span>
                  <span v-if="task.situation === '3'"><s>{{ task.title }}</s></span>
                </div>

                  <div class="d-flex justify-content-between">
                    <div class="d-flex">
                      <div v-if="task.attachments.length >= 1" class="mr-2">
                        <i class="fa-solid fa-file-invoice mr-1" style="color:#ADB5BD; font-size: 20px"></i>
                        <span style="color:#ADB5BD; font-size: 10px"> <b>{{ task.attachments.length }}</b> </span>
                      </div>
                    </div>

                  <div class="d-flex">
                  <img v-for="(user, index) in task.users.slice(0, 2)"
                      :key="user.id"
                      :src="this.Store.host + user.path_image"
                      v-tooltip.top="user.name"
                      :style="`height: 25px; border-radius: 50%; position: relative; left: ${index}px;`">

                  <div v-if="task.users.length > 2"
                      class="d-flex align-items-center justify-content-center"
                      style="width:25px; height: 25px; border:1px solid #3D8BFD; border-radius: 50%; background-color: #E7F1FF; position: relative; right: 0px;">
                    <span class="font-docs" style="color: #3D8BFD;"><b>+ {{ task.users.length - 2 }}</b></span>
                  </div>
                </div>
                  </div>
                </div>
              </transition-group>
                <div v-if="taskInputsVisibility[day]">
                <div class="task-input-content">
                    <input
                    type="text"
                    v-model="TaskName"
                    class="form-control"
                    placeholder="Nome da tarefa"
                    >
                </div>
                <div class="d-flex justify-content-between align-items-center mt-1">
                    <div class="task-input">
                    <button
                      id="close"
                      type="button text-black"
                      class="close"
                      @click="taskInputsVisibility = {}"
                      >
                          <span aria-hidden="true">&times;</span>
                      </button>
                </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        <div class="days-weekend" style="margin-left: 1rem;">
        <div v-for="(tasks, day, index) in formatDateWeekend" :key="day"
          class="kaban-collum-weekend"
          @dragover.prevent="onDragOver"
          @drop="onDrop(day, false, tasks, index+5)"
          >
            <div class="font-docs text-start d-flex justify-content-between w-100 mb-2" style="align-items: baseline;">
              <div>
                <span class="mr-1" style="color:#6C757D; font-size:14px"><b>{{ day.slice(0, 3) }}</b></span>
                <span><b>{{ getDateForDay(index+5) }}</b></span>
                <span v-if="isToday(index+5)" class="font-docs ml-1"> <b>(Hoje)</b></span>
              </div>
              <div>
                <i class="fa-solid fa-plus" style="color:#6C757D; cursor:pointer" @click="CreateTask(index+5, 0)"></i>
              </div>
            </div>
          <div class="kaban-task-list">
            <transition-group name="move" tag="div">
              <div
                v-for="(task, index) in tasks"
                :key="index"
                class="task font-docs mb-2"
                style="cursor: pointer;"
                @click="selectDay(task)"
                @dragstart="onDragStart(task, day)"
                @dragover.prevent="onDragOverTask(task)"
                @drop="onDrop(task, true)"
                @dragleave="onDragLeaveTask"
                draggable="true"
              >
              <div v-if="task.situation" class="mt-2 mb-2 d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center">
                  <i v-if="task.situation !== '3'" class="fa-regular fa-circle-check mr-2"></i>
                  <i v-if="task.situation === '3'" class="fa-solid fa-circle-check"></i>
                  <Tag v-if="task.situation === '1'" rounded style='background-color: #E9ECEF;color:#6C757D;'>
                    <div class='d-flex align-items-center gap-2'>
                      <span class='font-docs'><b>À iniciar</b></span>
                    </div>
                  </Tag>
                  <Tag v-if="task.situation === '2'" rounded style='background-color: #CFF4FC;color: #087990;'>
                    <div class='d-flex align-items-center gap-2'>
                      <span class='text-base font-docs'><b>Em andamento</b></span>
                    </div>
                  </Tag>
                  <Tag v-if="task.situation === '4'" rounded style='background-color: #F8D7DA;color: #842029;'>
                    <div class='d-flex align-items-center gap-2'>
                      <span class='text-base font-docs'><b>Bloqueada</b></span>
                    </div>
                  </Tag>
                  <Tag v-if="task.situation === '3'" rounded style='background-color: rgba(9,167, 6, 0.1);color: rgb(9 167 6);'>
                    <div class='d-flex align-items-center gap-2'>
                      <span class='text-base font-docs'><b>Concluída</b></span>
                    </div>
                  </Tag>
                </div>
                <div>
                  <div v-if="task.priority === '1'" class="d-flex">
                  <i class="fa-solid fa-angle-up mr-1" style="color:#0D6EFD;position: relative; top: 5px;"></i>
                  </div>

                  <div v-if="task.priority === '2'" class="d-flex">
                    <div class="d-grid mr-1">
                      <i class="fa-solid fa-angle-up" style="color:#FFC107;position: relative; top: 10px;"></i>
                      <i class="fa-solid fa-angle-up" style="color:#FFC107;"></i>
                    </div>
                  </div>

                  <div v-if="task.priority === '3'" class="d-flex">
                    <div class="d-grid mr-1">
                      <i class="fa-solid fa-angle-up" style="color:#F76659; position: relative; top: 10px;"></i>
                      <i class="fa-solid fa-angle-up" style="color:#F76659; position: relative; top: 0px;"></i>
                      <i class="fa-solid fa-angle-up" style="color:#F76659; position: relative; top: -10px;"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mb-3 mt-2">
                <span v-if="task.situation !== '3'">{{ task.title }}</span>
                <span v-if="task.situation === '3'"><s>{{ task.title }}</s></span>
              </div>

                <div class="d-flex justify-content-between">
                  <div class="d-flex">
                    <div v-if="task.attachments.length >= 1" class="mr-2">
                      <i class="fa-solid fa-file-invoice mr-1" style="color:#ADB5BD; font-size: 20px"></i>
                      <span style="color:#ADB5BD; font-size: 10px"> <b>{{ task.attachments.length }}</b> </span>
                    </div>
                  </div>

                <div class="d-flex">
                <img v-for="(user, index) in task.users.slice(0, 2)"
                    :key="user.id"
                    :src="this.Store.host + user.path_image"
                    v-tooltip.top="user.name"
                    :style="`height: 25px; border-radius: 50%; position: relative; left: ${index}px;`">

                <div v-if="task.users.length > 2"
                    class="d-flex align-items-center justify-content-center"
                    style="width:25px; height: 25px; border:1px solid #3D8BFD; border-radius: 50%; background-color: #E7F1FF; position: relative; right: 0px;">
                  <span class="font-docs" style="color: #3D8BFD;"><b>+ {{ task.users.length - 2 }}</b></span>
                </div>
              </div>
                </div>
              </div>
            </transition-group>
              <div v-if="taskInputsVisibility[day]">
              <div class="task-input-content">
                  <input
                  type="text"
                  v-model="TaskName"
                  class="form-control"
                  placeholder="Nome da tarefa"
                  >
              </div>
              <div class="d-flex justify-content-between align-items-center mt-1">
                  <div class="task-input">
                  <button
                    id="close"
                    type="button text-black"
                    class="close"
                    @click="taskInputsVisibility = {}"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
              </div>
              </div>
              </div>
          </div>
          </div>
        </div>
    </div>
</div>

<div v-if="Store.mobile">
  <ModalTask ref="ModalTask" @taskDescription="handleTaskEdit"/>
    <ModalCreateTask ref="ModalCreateTask" @CreateTask="handleTaskEdit"/>
    <div class="d-flex align-items-center">
      <i class="fa-solid fa-chevron-left mr-2" style="font-size: 28px; cursor:pointer" @click="voltarSemana()"></i>
      <i class="fa-solid fa-chevron-right mr-3" style="font-size: 28px; cursor:pointer" @click="avancarSemana()"></i>
      <span class="font-docs" style="font-size: 28px"><b>{{dateNow}}</b></span>
      <i class="fa-solid fa-arrows-rotate ml-3" style="font-size: 28px; cursor:pointer" @click="resetWeeks()"></i>
    </div>
    <div class="kaban">
        <div class="days">

          <div v-for="(tasks, day, index) in formatDateWeekMobile" :key="day"
            class="kaban-collum mb-3"
            @dragover.prevent="onDragOver"
            @drop="onDrop(day, false, tasks, index)"
            >
              <div class="font-docs text-start d-flex justify-content-between w-100 mb-2" style="align-items: baseline;">
                <div class="ml-2">
                  <span class="mr-1" style="color:#6C757D; font-size:14px"><b>{{ day.slice(0, 3) }}</b></span>
                  <span><b>{{ getDateForDay(index) }}</b></span>
                  <span v-if="isToday(index+1)" class="font-docs ml-1"> <b>(Hoje)</b></span>
                </div>
                <div class="mr-1">
                  <i class="fa-solid fa-plus" style="color:#6C757D; cursor:pointer" @click="CreateTask(index, 0)"></i>
                </div>
              </div>
            <div class="kaban-task-list">
              <transition-group name="move" tag="div" class="d-flex">
                <div
                  v-for="(task, index) in tasks"
                  :key="index"
                  class="task font-docs mb-2"
                  style="cursor: pointer;"
                  @click="selectDay(task)"
                  @dragstart="onDragStart(task, day)"
                  @dragover.prevent="onDragOverTask(task)"
                  @drop="onDrop(task, true)"
                  @dragleave="onDragLeaveTask"
                  draggable="true"
                >
                <div v-if="task.situation" class="mt-2 mb-2 d-flex justify-content-between align-items-center">
                  <div class="d-flex align-items-center">
                    <i v-if="task.situation !== '3'" class="fa-regular fa-circle-check mr-2"></i>
                    <i v-if="task.situation === '3'" class="fa-solid fa-circle-check"></i>
                    <Tag v-if="task.situation === '1'" rounded style='background-color: #E9ECEF;color:#6C757D;'>
                      <div class='d-flex align-items-center gap-2'>
                        <span class='font-docs'><b>À iniciar</b></span>
                      </div>
                    </Tag>
                    <Tag v-if="task.situation === '2'" rounded style='background-color: #CFF4FC;color: #087990;'>
                      <div class='d-flex align-items-center gap-2'>
                        <span class='text-base font-docs'><b>Em andamento</b></span>
                      </div>
                    </Tag>
                    <Tag v-if="task.situation === '4'" rounded style='background-color: #F8D7DA;color: #842029;'>
                      <div class='d-flex align-items-center gap-2'>
                        <span class='text-base font-docs'><b>Bloqueada</b></span>
                      </div>
                    </Tag>
                    <Tag v-if="task.situation === '3'" rounded style='background-color: rgba(9,167, 6, 0.1);color: rgb(9 167 6);'>
                      <div class='d-flex align-items-center gap-2'>
                        <span class='text-base font-docs'><b>Concluída</b></span>
                      </div>
                    </Tag>
                  </div>
                  <div>
                    <div v-if="task.priority === '1'" class="d-flex">
                    <i class="fa-solid fa-angle-up mr-1" style="color:#0D6EFD;position: relative; top: 5px;"></i>
                    </div>

                    <div v-if="task.priority === '2'" class="d-flex">
                      <div class="d-grid mr-1">
                        <i class="fa-solid fa-angle-up" style="color:#FFC107;position: relative; top: 10px;"></i>
                        <i class="fa-solid fa-angle-up" style="color:#FFC107;"></i>
                      </div>
                    </div>

                    <div v-if="task.priority === '3'" class="d-flex">
                      <div class="d-grid mr-1">
                        <i class="fa-solid fa-angle-up" style="color:#F76659; position: relative; top: 10px;"></i>
                        <i class="fa-solid fa-angle-up" style="color:#F76659; position: relative; top: 0px;"></i>
                        <i class="fa-solid fa-angle-up" style="color:#F76659; position: relative; top: -10px;"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mb-3 mt-2">
                  <span v-if="task.situation !== '3'">{{ task.title }}</span>
                  <span v-if="task.situation === '3'"><s>{{ task.title }}</s></span>
                </div>

                  <div class="d-flex justify-content-between">
                    <div class="d-flex">
                      <div v-if="task.attachments.length >= 1" class="mr-2">
                        <i class="fa-solid fa-file-invoice mr-1" style="color:#ADB5BD; font-size: 20px"></i>
                        <span style="color:#ADB5BD; font-size: 10px"> <b>{{ task.attachments.length }}</b> </span>
                      </div>
                    </div>

                  <div class="d-flex">
                  <img v-for="(user, index) in task.users.slice(0, 2)"
                      :key="user.id"
                      :src="this.Store.host + user.path_image"
                      v-tooltip.top="user.name"
                      :style="`height: 25px; border-radius: 50%; position: relative; left: ${index}px;`">

                  <div v-if="task.users.length > 2"
                      class="d-flex align-items-center justify-content-center"
                      style="width:25px; height: 25px; border:1px solid #3D8BFD; border-radius: 50%; background-color: #E7F1FF; position: relative; right: 0px;">
                    <span class="font-docs" style="color: #3D8BFD;"><b>+ {{ task.users.length - 2 }}</b></span>
                  </div>
                </div>
                  </div>
                </div>
              </transition-group>
                <div v-if="taskInputsVisibility[day]">
                <div class="task-input-content">
                    <input
                    type="text"
                    v-model="TaskName"
                    class="form-control"
                    placeholder="Nome da tarefa"
                    >
                </div>
                <div class="d-flex justify-content-between align-items-center mt-1">
                    <div class="task-input">
                    <button
                      id="close"
                      type="button text-black"
                      class="close"
                      @click="taskInputsVisibility = {}"
                      >
                          <span aria-hidden="true">&times;</span>
                      </button>
                </div>
                </div>
                </div>
            </div>
            </div>
        </div>
    </div>
</div>
</div>
</template>
